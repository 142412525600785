import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import jquery from 'jquery';
import Mailcheck from 'mailcheck';

// prevent double submits
jquery.validator.setDefaults({
    submitHandler: (form: HTMLFormElement) => {
        $('button', form).attr('disabled', 'disabled');
        return true;
    },
});

// email validation should be more strict than the default version
const defaultEmailValidator = jquery.validator.methods.email;
jquery.validator.methods.email = function (
    value: any,
    element: any,
    param: any,
) {
    if (this.optional(element)) {
        return true;
    }

    if (!looksLikeAnEmail(value) || isInvalidTld(value)) {
        return false;
    }

    return defaultEmailValidator.call(this, value, element, param);
};

// extend jquery range validator to work for required checkboxes
const defaultRangeValidator = jquery.validator.methods.range;
jquery.validator.methods.range = function (
    value: any,
    element: HTMLInputElement,
    param: any,
) {
    if (element.type === 'checkbox') {
        // if it's a checkbox return true if it is checked
        return element.checked;
    } else {
        // otherwise run the default validation function
        return defaultRangeValidator.call(this, value, element, param);
    }
};

// make mailcheck work with jquery validation
$('input[type="email"]').on('blur', function (event: Event) {
    const element = jQuery(event.target);
    Mailcheck.run({
        domains: ['gmail.com', 'hotmail.com', 'icloud.com'],
        secondLevelDomains: [],
        topLevelDomains: ['com', 'dk', 'net', 'uk'],
        suggested: (suggestion: any) => {
            element
                .siblings('[data-valmsg-for].field-validation-valid')
                .removeClass('field-validation-valid')
                .html(
                    'Mente du <span class="cursor-pointer underline">' +
                        suggestion.full +
                        '</span>?',
                )
                .one('click', function () {
                    element.val(suggestion.full);
                    $(this).addClass('field-validation-valid');
                });
        },
        empty: (): void => {},
        email: element.val() as string,
    });
});

function isInvalidTld(email: string) {
    return /\.(vom|con|clm|)$/i.test(email);
}

function looksLikeAnEmail(email: string) {
    return /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i.test(email);
}
